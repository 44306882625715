import { template as template_2ca3e20023844b75ac1fb49e94797f96 } from "@ember/template-compiler";
const FKControlMenuContainer = template_2ca3e20023844b75ac1fb49e94797f96(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
